import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, WEB_ANALYTICS_VERSIONDETAILS } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Maintain Version Details", "search-by": "Version,VersionDesc,FinYear,Alias", "ph-search-by": "Search by Version, FinYear, Alias", "has-active": false, "has-filter-inactive": false }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Details for Country")]), _c("div", { staticClass: "country-filter" }, [_c("select-filter", { key: "Country", attrs: { "default-active-first-option": "", "source": "country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel }, on: { "change": _vm.onCountryFilterChange } })], 1)];
  }, proxy: true }, _vm.can(_vm.permissions.maintain) ? { key: "create-buttons", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAddClick } }, [_vm._v("Add New")])];
  }, proxy: true } : null, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) && _vm.showAction(record) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) && _vm.showAction(record) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDelete(record);
    } } })], 1) : _vm._e()];
  } }], null, true) }, [_c("text-field", { key: "Country", attrs: { "data-index": "country", "title": "Country" } }), _c("text-field", { key: "SortOrder", attrs: { "data-index": "sortOrder", "title": "Sort Order", "sorter": true } }), _c("text-field", { key: "Version", attrs: { "data-index": "versionDesc", "title": "Version" } }), _c("text-field", { key: "finYear", attrs: { "data-index": "finYear", "title": "Fin Year" } }), _c("text-field", { key: "Alias", attrs: { "data-index": "alias", "title": "Alias", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
var MaintainVersionDetails_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".country-filter[data-v-04df398a]{min-width:160px}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "MaintainVersionDetails",
  inject: ["createRoute", "crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      },
      countries: [],
      apiUrl: apiUrl$1,
      listVersionDetails: [],
      nonActionVersion: ["1", "8", "9", "11", "15"]
    };
  },
  created() {
    this.getCountries();
  },
  methods: {
    onAddClick() {
      this.$router.push(this.createRoute);
    },
    onCountryFilterChange(country) {
      this.crud.clearFilters();
      this.crud.deleteFilter("IsInactive");
      this.crud.setFilter("Country", country);
      const { pageSize } = this.crud.getPagination();
      this.crud.setPagination({ page: 1, pageSize });
      this.crud.fetchList();
    },
    onDelete(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Version Detail?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Version Detail?`,
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.delete(`${this.apiUrl}/web-analytics/version-details`, {
          data: { country: record.country, sortOrder: record.sortOrder }
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    },
    showAction(record) {
      return this.nonActionVersion.indexOf(record.version) > -1 ? false : true;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "04df398a", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var MaintainVersionDetails = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.version-details", "api-url": _vm.apiUrl, "create-route": "/web-analytics/maintain-version-details/create", "edit-route": "/web-analytics/maintain-version-details/:id", "page": _vm.page } }, [_c("maintain-version-details")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    MaintainVersionDetails
  },
  data() {
    return {
      page: WEB_ANALYTICS_VERSIONDETAILS,
      apiUrl,
      itemsMenu: [
        {
          key: "profitability",
          title: "Profitability",
          path: ""
        },
        {
          key: "maintain-version-details",
          title: "Maintain Version Details",
          path: "/web-analytics/maintain-version-details"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
